form.hide-labels label {
  display: none;
}

form .autoComplete_wrapper {
  flex: 1 1 auto;
  z-index: 999;
}

form .autoComplete_wrapper li>i {
  float: right;
  margin-top: 2px;
  margin-left: auto;
  display: none;
}

form .autoComplete_wrapper li:hover>i {
  display: inline-block;
}

#blockchainAddressForm button.dropdown-toggle {
  padding-left: 2px;
  padding-right: 4px;
}

#blockchainAddressForm ul.dropdown-menu>li img {
  margin-top: -3px;
  max-height: 14px;
}

html .has-metamask {
  display: none;
}

html.has-metamask .has-metamask {
  display: inherit;
}

html .has-camera {
  display: none;
}

html.has-camera .has-camera {
  display: inherit;
}

#zsfeedbackwidgetdiv #advwebForm {
  max-width: 100% !important;
  padding: 30px 0 0 0 !important;
}

html[data-bs-theme=dark] #zsfeedbackwidgetdiv #advwebForm {
  background-color: rgb(33, 37, 41) !important;
  color: rgb(206, 212, 218) !important;
}

#zsfeedbackwidgetdiv .zsfeedbkrpanel {
  max-width: 100% !important;
  border: 0 !important;
}

html[data-bs-theme=dark] #zsfeedbackwidgetdiv .zsfeedbkrpanel {
  background-color: rgb(33, 37, 41) !important;
  color: rgb(206, 212, 218) !important;
}

#zsfeedbackwidgetdiv .fbfpoptitle {
  display: none;
}

#zsfeedbackwidgetdiv .feedbackformmdiv {
  padding: 0 !important;
}

#zsfeedbackwidgetdiv .zspreattscreendiv {
  padding: 5px 0 0 0 !important;
}

html[data-bs-theme=dark] #zsfeedbackwidgetdiv .fbtxtnf {
  background-color: rgb(40, 43, 46) !important;
  color: rgb(206, 212, 218) !important;
  border: 0 !important;
}

html[data-bs-theme=dark] #zsfeedbackwidgetdiv .feedbtxtfield {
  background-color: rgb(40, 43, 46) !important;
  color: rgb(206, 212, 218) !important;
  border: 0 !important;
}

html[data-bs-theme=dark] #zsfeedbackwidgetdiv .feedbselefield {
  background-color: rgb(40, 43, 46) !important;
  color: rgb(206, 212, 218) !important;
  border: 0 !important;
}

html[data-bs-theme=dark] #zsfeedbackwidgetdiv .feedtxtarea {
  background-color: rgb(40, 43, 46) !important;
  color: rgb(206, 212, 218) !important;
  border: 0 !important;
}

html[data-bs-theme=dark] #zsfeedbackwidgetdiv .FBWordVerify input {
  background-color: rgb(40, 43, 46) !important;
  color: rgb(206, 212, 218) !important;
  border: 0 !important;
}