.flex-conector-container {
	margin: auto;
	border-radius: 16px;
	max-width: 680px;
	padding: 20px;
}
.cont-connector-wallets {
	gap: 10px;
	width: 100%;
	flex-direction: column;
}
.headlineDashboardConnectWallets {
	gap: 20px;
	margin-bottom: 30px;
}
.headline-icon-dashboardConnectWallets {
	background-color: #a1a8de26;
	border-radius: 50%;
	height: 50px;
	min-height: 50px;
	min-width: 50px;
	width: 50px;
}
.connector-container {
	gap: 10px;
	margin: 0;
	padding: 0;
}
.connector-item {
	border-radius: 12px;
	width: 100%;
	min-width: 150px;
	flex: 1;
}
.connector-item-inner {
	background-color: #fff;
	border-radius: 12px;
	padding: 10px;
	position: relative;
	width: 100%;
	border: 2px solid #eaecf0;
}
.cont-card-icon-text {
	display: flex;
	align-items: center;
	gap: 10px;
}
.more-card {
	width: 40px;
	height: 40px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.icon-wrapper {
	display: flex;
	flex: 0 0 auto;
	width: 30px;
	height: 30px;
}

.card-image {
	object-fit: contain;
	width: 100% !important;
	position: relative !important;
	height: unset !important;
}
.description-wrapper {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.name {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
	white-space: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0;
	font-weight: 400;
	text-transform: capitalize;
	color: #464343;
}

.button-with-arrow {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	font-weight: 500;
	margin-top: 6px;
	color: #424141;
}
.icon-Arrow-More {
	font-size: 20px;
	width: 20px;
	height: 20px;
}
.connector-btn-wallets {
	flex-grow: 1;
	height: auto;
	justify-content: center;
}
.connector-item-inner:hover {
	border: 2px solid #ffb347;
	box-shadow: 0 12px 12px 0 rgba(255, 147, 50, 0.15);
}
.button-with-arrow {
	color: #ffb347;
}

.view-portfolio-btn {
	display: flex;
	align-items: center;
	color: #01305b;
	padding: 20px;
	border-radius: 5rem;
	background-color: transparent;
	outline: none;
	gap: 10px;
	border: 1px solid #01305b;
}

.view-portfolio-btn svg path {
	fill: #01305b;
}

html[data-bs-theme='dark'] {
	.connector-item-inner {
		background: #24282b;
		background-color: #24282b;
		border: 2px solid #32383e;
	}
	.connector-item-inner:hover {
		border: 2px solid #ffb347;
		box-shadow: 0 12px 12px 0 rgba(255, 147, 50, 0.15);
	}
	.button-with-arrow {
		color: white;
	}

	.name {
		color: #bfbfbf;
	}
	.view-portfolio-btn {
		color: #bfbfbf;
		border: 1px solid #32383e;
	}
	.view-portfolio-btn svg path {
		fill: #fff;
	}
}
